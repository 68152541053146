<template>
  <vs-row vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12">
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
      <div v-if="debug == 'true'">Title: {{ title }}</div>
      <vs-chip
        :color="color"
        :class="size ? (size=='small' ? 'chip-small pt-2 pb-2 pl-2 pr-5' : 'chip-large pt-2 pb-2 pl-2 pr-5') : 'chip-medium pt-2 pb-2 pl-2 pr-5'

        "
      >{{ title }}
      <div v-if="icon != null" class="ml-2 mt-1">
        <span>
         <vs-icon :icon="icon" size="small"></vs-icon>
       </span>
      </div>
</vs-chip>
    </vs-col>
  </vs-row>
</template>

      <script>
export default {
  props: { icon: String, title: String, color: String, debug: Boolean, size: String }
};
</script>
<style>
  .chip-medium{
    font-size: 14px; color: black !important; font-weight: 500;
  }
  .chip-small{
    font-size: 12px; color: black !important; font-weight: 500;
  }
  .chip-large{
    font-size: 18px; color: black !important; font-weight: 500;
  }
</style>
