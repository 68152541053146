<template>
  <vs-button
    @click="add()"
    :icon="icon != undefined ? icon : 'add'"
    :type="type != undefined ? type : 'relief'"
    color="success"
    :disabled="visualizar"
    class="pull-right float-right"
    :size="size != undefined ? size : 'small'"
  >{{ text != undefined ? text : "Cadastrar" }}</vs-button>
</template>

<script>
export default {
  props: { text: String, icon: String, size: String, type: String, visualizar: Boolean },
  data() {
    return {};
  },
  methods: {
    add() {
      this.$emit("func");
    }
  },
};
</script>

<style></style>
