<template>
  <div class="w-full">
    <slot name="header"></slot>
    <vs-list-item
      class="w-full ml-4 mr-4"
      v-for="(item, index) in list"
      v-bind:key="index"
      :title="item.nome"
    >
      <vs-switch color="success" @click="update(item)" v-model="item.status" />
    </vs-list-item>
  </div>
</template>

<script>
export default {
  props: { list: Array },
  data() {
    return {
      associados: []
    };
  },
  methods: {
    update(item) {
      const find = this.associados.find(x => x.id == item.id);
      if (find != undefined) {
        const associados = this.associados.filter(x => x.id != item.id);
        this.associados = associados;
      } else {
        this.associados.push(item);
      }
      this.$emit("update", this.associados);
    }
  }
};
</script>

<style>
</style>