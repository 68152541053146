import EditButton from "./EditButton.vue";
import vside from "./v-side.vue";
import SideBar from "./SideBar.vue";
import SuccessButton from "./SuccessButton.vue";
import DangerButton from "./DangerButton.vue";
import RemoveItem from "./RemoveItem.vue"
import ListSwitch from "./ListSwitch.vue";
import Center from "./Center.vue"
import ChipCenter from "./ChipCenter.vue"
import Badge from "./Badge.vue"
import HistoricList from "./HistoricList.vue"
import Validator from "./Validator.vue";
// import circleChart from "./circleChart.vue";
// import pieChart from "./pieChart.vue";
// import donutChart from "./donutChart.vue";
// import infoTooltip from "./infoTooltip.vue";
// import radialChart from "./radialChart.vue";
// import faturamentoChart from "./faturamentoChart.vue";
// import parcelasChart from "./parcelasChart.vue";
// import barChart from "./barChart.vue";
// import metaChart from "./metaChart.vue";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';


export default {
  EditButton,
  SuccessButton,
  DangerButton,
  RemoveItem,
  SideBar,
  vside,
  ListSwitch,
  Badge,
  ChipCenter,
  Center,
  HistoricList,
  Validator,
  // metaChart,
  // pieChart,
  // faturamentoChart,
  // donutChart,
  // parcelasChart,
  // radialChart,
  // circleChart,
  // barChart,
  // infoTooltip,
  VuePerfectScrollbar
}
