<template>
  <a href="#" @click="disabled == false ? edit(): 1">
    <feather-icon :style="disabled == true ? 'color: lightgrey;': ''" icon="EditIcon" class="mr-2" />
  </a>
</template>

<script>
export default {
  props: { disabled: Boolean},
  name: "EditButton",
  data() {
    return {};
  },
  methods: {
    edit() {
      this.$emit("func");
    }
  }
};
</script>

<style></style>
