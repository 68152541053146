<template>
  <div class="w-full">
    <Center>
      <div slot="content" v-if="item">
        <vs-divider class="py-0 my-0 mb-2">{{ dividerText != undefined ? dividerText : "Opções" }}</vs-divider>
        <div class="btn-group w-full">
          <vs-button
            :color=" item.lixeira ? 'success' : 'danger'"
            size="small"
            class="mr-2"
            @click="confirmLixeira()"
          >
            {{
            item.lixeira == 0 ? "Desativar" : "Ativar"
            }}
          </vs-button>
          <vs-button type="border" color="danger" size="small" @click="confirmExcluir()">Excluir</vs-button>
        </div>
      </div>
    </Center>
  </div>
</template>

<script>
import Center from "./Center";
export default {
  props: {
    dividerText: String,
    item: Object
  },
  components: {
    Center
  },
  methods: {
    excluir() {
      this.$emit("excluir");
    },
    lixeira() {
      this.$emit("status");
    },
    confirmLixeira() {
      this.$emit("func");
      this.$vs.dialog({
        type: "confirm",
        color: !this.item.lixeira ? "danger" : "success",
        title: `Confirmar`,
        acceptText: 'Confirmar',
        cancelText: 'Cancelar',
        text: !this.item.lixeira
          ? "Confirmar desativação do item?"
          : "Confirmar ativação do item?",
        accept: this.lixeira
      });
    },
    confirmExcluir() {
      this.$emit("func");
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmar`,
        acceptText: 'Confirmar',
        cancelText: 'Cancelar',
        text:
          "Confirmar Exclusão do item? (Espero que saiba o que esta fazendo)",
        accept: this.excluir
      });
    }
  }
};
</script>

<style></style>
