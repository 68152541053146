<template>
  <vs-list class="w-full">
    <div v-if="debug == 'true'">{{ list }}</div>
    <vs-list-item
      v-for="(item, index) in list"
      v-bind:key="index"
      :icon="item.icon"
      :title="item.title"
    >
      <div v-if="item.date">
        <b>Data:</b>
        {{ item.date }}
      </div>
      <div v-if="item.hour">
        <b>Hora:</b>
        {{ item.hour }}
      </div>
    </vs-list-item>
  </vs-list>
</template>

<script>
export default {
  props: { list: Array, debug: Boolean },
  name: "HistoricList"
};
</script>
