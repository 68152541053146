<template>
  <vs-button
    @click="add()"
    :icon="icon != undefined ? icon : 'close'"
    type="border"
    color="danger"
    :size="size != undefined ? size : 'small'"
  >{{ text != undefined ? text : "Sair" }}</vs-button>
</template>

<script>
export default {
  name: "DangerButton",
  props: { text: String, icon: String, size: String },
  data() {
    return {};
  },
  methods: {
    add() {
      this.$emit("func");
    }
  }
};
</script>

<style></style>
