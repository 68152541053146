<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    :class="'add-new-data-sidebar-'+size  "
    spacer
    v-model="active"
  >
   <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" v-if="title != ''">
        <vs-button style="cursor: default; font-weight: 500;"  color="dark" size="" type="line">{{title}}</vs-button>
    </vs-col>

    <vs-divider class="mb-0" v-if="title != ''"></vs-divider>

    <div class="w-full px-2">
        <slot name="body"></slot>

    </div>

    <slot name="bodyNoPadding"></slot>

    <div class="flex flex-wrap items-center justify-center p-2" slot="footer">
      <vs-row class="w-full">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="pb-3">
        <RemoveItem
          :item="item"
          @excluir="excluir"
          @status="status"
          @func="hide"
          v-if="title.indexOf('CADASTRO') == -1 && disabled == false"
        />
        </vs-col>
        <vs-col vs-justify="right" vs-align="right" vs-w="5.9">
          <SuccessButton
            size="default"
            :visualizar="visualizar"
            :text="textSucces"
            :class="size != 'small' ? '' : 'w-full'"
            :icon="icon != 'add' ? icon : icon"
            @func="add()"
          ></SuccessButton>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="0.2"
        ></vs-col>
        <vs-col vs-justify="left" vs-align="left" vs-w="5.9">
          <DangerButton
            size="default"
            @func="hide()"
            :class="size != 'small' ? '' : 'w-full'"
          ></DangerButton>
        </vs-col>
      </vs-row>
    </div>
  </vs-sidebar>
</template>

<script>
import SuccessButton from "./SuccessButton.vue";
import EditButton from "./EditButton.vue";
import RemoveItem from "./RemoveItem.vue";
import DangerButton from "./DangerButton.vue";

export default {
  name: "SideBar",
  props: {
    title: String,
    active: Boolean,
    textSucces: String,
    size: String,
    icon: String,
    disabled: Boolean,
    item: Object,
    visualizar: false
  },
  data() {
    return {

    };
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
    add() {
      this.$emit("success");
    },
    excluir() {
      this.$emit("excluir");
    },
    status() {
      this.$emit("status");
    }
  },
  components: {
    SuccessButton,
    EditButton,
    RemoveItem,
    DangerButton,
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar- {
  /deep/ .vs-sidebar--background {
    z-index: 52010;
  }

  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.add-new-data-sidebar-small {
  /deep/ .vs-sidebar--background {
    z-index: 52010;
  }

  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.add-new-data-sidebar-large {
  /deep/ .vs-sidebar--background {
    z-index: 52010;
  }

  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 700px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;


      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
.add-new-data-sidebar-huge {
  /deep/ .vs-sidebar--background {
    z-index: 52010;
  }

  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 1000px;
    max-width: 100vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
.add-new-data-sidebar-huger {
  /deep/ .vs-sidebar--background {
    z-index: 52010;
  }

  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 1300px;
    max-width: 100vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style>
