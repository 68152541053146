<template>
    <!-- <span class="badge" >{{text}}asdasdasd</span> -->
    <div>
     <vs-chip v-if="size == 'huge'" :color="color" style="font-size: 18px;"><span :style="'color: ' + textColor">{{text}}</span></vs-chip>
     <vs-chip v-if="size == 'large'" :color="color" style="font-size: 15px;"><span :style="'color: ' + textColor">{{text}}</span></vs-chip>
     <vs-chip v-if="size != 'large' && size != 'huge'" :color="color"><span :style="'color: ' + textColor">{{text}}</span></vs-chip>
    </div>
</template>
<script>
    export default {
        props: { text: String, color: String, size: String, textColor: String }
    };
</script>
<style>
</style> 
