<template>
  <span class="text-danger text-sm" v-show="error">{{
    $validators.email.msg
  }}</span>
</template>

<script>
export default {
  props: { error: Boolean }
};
</script>

<style></style>
