<template>
  <vs-sidebar
    position-right
    parent="body"
    default-index="1"
    color="primary"
    :class="'add-new-data-sidebar-' + size"
    spacer
    v-model="active"
  >
    <div class="w-full px-2">
      <slot name="body"></slot>
    </div>

    <slot name="bodyNoPadding"></slot>

    <div
      class="flex flex-wrap items-center justify-center p-2"
      slot="footer"
    ></div>
  </vs-sidebar>
</template>

<script>
export default {
  name: "v-side",
  props: {
    title: String,
    active: Boolean,
    textSucces: String,
    size: String,
    icon: String,
    disabled: Boolean,
    item: Object,
    visualizar: false
  },
  data () {
    return {}
  },
  methods: {
    hide () {
      this.$emit("hide")
    }
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar- {
  /deep/ .vs-sidebar--background {
    z-index: 52010;
  }

  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.add-new-data-sidebar-small {
  /deep/ .vs-sidebar--background {
    z-index: 52010;
  }

  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.add-new-data-sidebar-large {
  /deep/ .vs-sidebar--background {
    z-index: 52010;
  }

  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 700px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
.add-new-data-sidebar-huge {
  /deep/ .vs-sidebar--background {
    z-index: 52010;
  }

  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 1000px;
    max-width: 100vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
.add-new-data-sidebar-huger {
  /deep/ .vs-sidebar--background {
    z-index: 52010;
  }

  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 1300px;
    max-width: 100vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
.add-new-data-sidebar-huger-up-size {
  /deep/ .vs-sidebar--background {
    z-index: 52010;
  }

  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 1350px;
    max-width: 100vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style>
