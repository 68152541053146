<template>
  <vs-row vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12">
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
      <slot name="content"></slot>
    </vs-col>
  </vs-row>
</template>
      
<script>
export default {};
</script>
      
<style>
</style>